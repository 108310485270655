/**
 * Простой собиратор пути
 * разделитель `/`
 * @param {string[]} parts - части пути
 * @returns {string} path
 */
export const pathJoin = (...parts) => {
  const separator = "/";
  const validParts = parts
    .filter((n) => {
      return n && ["string", "number"].includes(typeof n);
    })
    .map((n) => String(n));
  return validParts
    .map((part, index) => {
      if (index) {
        part = part.replace(new RegExp("^" + separator), "");
      }
      if (index !== parts.length - 1) {
        part = part.replace(new RegExp(separator + "$"), "");
      }
      return part;
    })
    .join(separator);
};

const IGNORE_ERRORS_STRINGS = [];

/**
 *
 * @param {*} value - any
 * @returns {string} - текст первой ошибки
 */
export const getFirstStringValue = (value) => {
  let result;
  const valueType = typeof value;
  if (!value || valueType === "number") return;

  const isArray = Array.isArray(value);

  if (valueType === "string" && !IGNORE_ERRORS_STRINGS.includes(value)) {
    return value;
  }

  if (isArray) {
    for (const item of value) {
      if (!item) continue;
      const string = getFirstStringValue(item);
      if (string && !IGNORE_ERRORS_STRINGS.includes(value)) {
        result = string;
        break;
      }
    }
  }

  if (!isArray && valueType === "object") {
    for (let key in value) {
      if (!value[key]) continue;
      const string = getFirstStringValue(value[key]);
      if (string && !IGNORE_ERRORS_STRINGS.includes(value)) {
        result = string;
        break;
      }
    }
  }
  return result;
};

const getApiValidationMessage = (data) => {
  let error = null;
  if (Array.isArray(data) && "validation_error" in data[0]) {
    error = data[0];
  } else if (typeof data === "object" && "validation_error" in data) {
    error = data;
  }

  if (error) {
    const result = {
      field: error.field,
      message: `Ошибка валидации в поле ${error.field}: ${error.validation_error}`,
    };

    switch (error.validation_error) {
      case "unique":
        result.message = `Ошибка валидации. Поле ${error.field} должно быть уникальным. Значение ${error.value} уже используется.`;
        break;
      case "required":
        result.message = `Ошибка валидации. Поле ${error.field} обязательно для заполнения`;
    }

    return result;
  }
  return;
};

/**
 * Интерфейс сетевой ошибки
 * @typedef {Object} ApiError
 * @property {number} status - статус код ошибки
 * @property {string} message - сообщение
 */

/**
 * @constructor
 * @extends Error
 * @returns {APIError}
 *
 */
export class APIError extends Error {
  /**
   * @param {Object} error - axios request error
   */
  constructor(error) {
    const status = error?.response?.status || 532;
    let message = `Возникла непредвиденная ошибка (${status})`;
    const errorData = error?.response?.data;
    let validationError = getApiValidationMessage(errorData);

    if (status === 504) {
      message = "Превышено время ожидания ответа от сервера";
    } else if (validationError) {
      message = validationError.message;
    } else if (typeof errorData === "object") {
      const firstErrorMessage = getFirstStringValue(errorData);
      if (firstErrorMessage) message = firstErrorMessage;
      if (firstErrorMessage === "Not Found") {
        message = `Запись не найдена`;
      }
    }
    super(message);
    if (
      error?.response?.headers["content-type"] !== "text/html; charset=utf-8"
    ) {
      this.originalData = error.response?.data;
    } else {
      this.originalData = {};
    }
    if (validationError) {
      this.field = validationError.field;
    }
    this.status = status;
  }
}

/**
 * Получает всех итемов по заданным параметрам
 * @param {import("axios").AxiosInstance} transport
 * @param {import("axios").AxiosRequestConfig} config
 * @returns {Array{}} массив итемов
 *
 */
export const getAllPages = async (transport, config) => {
  const reqConfig = { ...config, method: "GET" };
  const defaultChunkSize = 30;
  if (!config?.params) {
    reqConfig.params = {
      limit: defaultChunkSize,
      offset: 0,
    };
  } else if (!config.params.limit) {
    reqConfig.params.limit = defaultChunkSize;
  }
  const { data } = await transport.request(reqConfig);
  const pagesCount = Math.ceil(data.count / reqConfig.params.limit);
  let result = [...data.results];

  if (pagesCount > 1) {
    const requests = [];
    for (let i = 0; i < pagesCount - 1; i++) {
      requests.push(
        transport({
          ...reqConfig,
          params: {
            ...reqConfig.params,
            offset: (i + 1) * reqConfig.params.limit,
          },
        })
      );
    }

    const reqResults = await Promise.all(requests);

    return reqResults.reduce((acc, value) => {
      return [...acc, ...value.data.results];
    }, result);
  }

  return result;
};
