<template>
  <span class="u-info"
    ><slot></slot><span class="u-info__block">{{ userId }}</span></span
  >
</template>

<script>
export default {
  name: "AppUserInfo",
  props: {
    userId: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.u-info {
  position: relative;

  &__block {
    position: absolute;
  }
}
</style>
