export const ACCESS_TOKEN = "kk_token";
export const DEFAULT_DIALOG_WIDTH = "600px";
export const TRACK_TYPE_COMMUNITY = "community";
export const TRACK_TYPE_EVENT = "event";
export const TRACK_TYPE_NAMES = {
  [TRACK_TYPE_COMMUNITY]: "Треки активности в сообществах",
  [TRACK_TYPE_EVENT]: "Треки мероприятия",
};
export const WINNER_ROLE_NAME = "Победитель";
export const PARTICIPANT_ROLE_NAME = "Участник";
export const PRIZE_WINNER_ROLE_NAME = "Призер";

export const BASE_ACHIEVEMENT_ROLE_NAMES = [
  WINNER_ROLE_NAME,
  PRIZE_WINNER_ROLE_NAME,
  // "Финалист",
  PARTICIPANT_ROLE_NAME,
];

export const TALENT_ROLES = [
  PARTICIPANT_ROLE_NAME,
  PRIZE_WINNER_ROLE_NAME,
  WINNER_ROLE_NAME,
];

export const TRACK_CODES = {
  GIT_REPO: "git_repo",
  KAGGLE: "kaggle",
  STACKOVERFLOW: "stackoverflow",
  STEPIK: "stepik",
  ONLINE_COURSE: "online_course",
  OFFLINE_COURSE: "offline_course",
  COMPETITION: "competition",
  TESTING: "testing",
};

export const STEPIK_TYPE_TESTING = "testing";
export const STEPIK_TYPE_EDUCATION = "education";

export const TASK_STATUS_PENDING = "pending";
export const TASK_STATUS_RUNNING = "running";
export const TASK_STATUS_COMPLETE = "completed";
export const TASK_STATUS_FAILURE = "failure";

/**
 * Ачивка На рассмотрении
 */
export const ACHIEVEMENT_NO_STATUS = "";
export const ACHIEVEMENT_STATUS_USER_REJECTED = "r";
export const ACHIEVEMENT_STATUS_MODERATION = "m";
export const ACHIEVEMENT_STATUS_REJECTED = "d";
export const ACHIEVEMENT_STATUS_APPROVED = "+";

export const DEFAULT_OUTCOME_COEF = 1;
