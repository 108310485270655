import Vue from "vue";
import isInteger from "lodash/isInteger";

import {
  ValidationProvider,
  extend,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import {
  required,
  max,
  numeric,
  min,
  email,
  integer,
  double,
  min_value,
  max_value,
  is_not,
  is,
} from "vee-validate/dist/rules";
localize("ru", ru);

const config = {
  mode: "eager",
};

extend("numeric", numeric);
extend("integer", integer);
extend("double", double);
extend("max", max);
extend("min", min);
extend("email", email);
extend("min_value", min_value);
extend("max_value", max_value);
extend("is", is);
extend("is_not", {
  ...is_not,
  message(f, settings) {
    return `Значение этого поля не должно быть ${settings._value_}`;
  },
});

extend("required", {
  ...required,
  message: "Это обязательное поле",
});

extend("ids", {
  validate(value) {
    const array = String(value)
      .split(",")
      .map((n) => n.trim());
    return array.every((v) => {
      return isInteger(Number(v)) && v > 0;
    });
  },
  message() {
    return "Список должен состоять из целочисленных значений, разделенных запятой";
  },
});

configure(config);
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
