import { talentClient } from "@/api";
import { getAllPages } from "@/api/utils";
import { BASE_ACHIEVEMENT_ROLE_NAMES } from "@/constants";
import { eventSlice } from "@/api/utils/dataParsers";
export default {
  namespaced: true,
  state: {
    achievementRoles: [],
    langs: [],
    events: {},
  },
  getters: {
    baseAchievementsRoles(state) {
      if (!state.achievementRoles.length) return [];
      return BASE_ACHIEVEMENT_ROLE_NAMES.map((name) => {
        return state.achievementRoles.find((role) => role.name === name);
      }).filter(Boolean);
    },
  },
  mutations: {
    SET_ROLES(state, payload) {
      state.achievementRoles = payload;
    },
    SET_LANGS(state, payload) {
      state.langs = payload;
    },

    ADD_EVENTS(state, payload) {
      state.events = { ...state.events, ...payload };
    },
  },
  actions: {
    async getAllAchievementRoles({ commit, state }) {
      if (state.achievementRoles.length) return state.achievementRoles;
      const items = await getAllPages(talentClient, {
        url: "/achievements/roles/",
        params: {
          limit: 80,
        },
      });
      commit("SET_ROLES", items);
      return items;
    },
    async getAllLanguages({ commit, state }) {
      if (state.langs.length) return state.state;
      const items = await getAllPages(talentClient, {
        url: "/integrations/languages/",
        params: {
          limit: 30,
        },
      });
      commit("SET_LANGS", items);
      return items;
    },
    async getEventsByIds({ state, commit }, ids = []) {
      if (!ids?.length) return [];
      const newEvents = ids.filter((id) => !state.events[id]);
      if (!newEvents.length) return;
      const { data } = await talentClient({
        method: "GET",
        url: "/events/",
        params: {
          ids: newEvents.join(","),
          limit: 100,
          offset: 0,
        },
      });
      const events = data.results.reduce((acc, value) => {
        acc[value.id] = eventSlice(value);
        return acc;
      }, {});
      commit("ADD_EVENTS", events);
    },
  },
};
