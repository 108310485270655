<template>
  <div>
    <component :is="appComponent" />
    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import AppLayout from "@/layouts/App";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
export default {
  name: "AppRoot",
  components: {
    AppLayout,
    ConfirmDialog,
  },
  metaInfo: {
    title: "Компетенции",
    titleTemplate: `${process.env.VUE_APP_TITLE} | %s`,
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    appComponent() {
      const { layout } = this;
      return layout || "router-view";
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>

<style lang="scss" scoped>
.talent-toast {
  max-width: 450px;
}
</style>
