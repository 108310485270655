import axios from "axios";
import Cookies from "js-cookie";
export const baseURL = process.env.VUE_APP_TALENT_API_URL;
import { APIError } from "./utils";
import { ACCESS_TOKEN } from "@/constants";
console.log("baseUrl", baseURL);
/**
 * Клиент для обращения к API Talent
 * `baseURL` = process.env.VUE_APP_TALENT_API_URL
 * На дев сервере, запросы к сервису проксируются
 * через коробочный WebpackDevServer.proxy
 * На стейдже и проде, проксируется через LWS
 * @link ../../lws.config.js
 */
export const talentClient = axios.create({
  baseURL,
});

talentClient.interceptors.request.use((config) => {
  const token = Cookies.get(ACCESS_TOKEN);
  if (token && config) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

talentClient.interceptors.response.use(
  (res) => res,
  (error) => {
    return Promise.reject(new APIError(error));
  }
);
