<template>
  <v-app class="root-app">
    <v-app-bar app color="white" flat class="root-app__header">
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          v-if="user"
          @click="navOpen = !navOpen"
        ></v-app-bar-nav-icon>
        <router-link to="/" class="ml-3 d-flex app-link">
          <img
            src="@/assets/kd_logo.svg"
            width="40px"
            :alt="$store.state.appTitle"
            class="mr-2"
          />

          <div class="app-name">{{ $store.state.appTitle }}</div>
        </router-link>
        <v-btn v-for="link in links" :key="link" text>
          {{ link }}
        </v-btn>

        <v-spacer></v-spacer>
        <template v-if="user">
          <form action="/scores" method="GET">
            <v-text-field
              class="mx-4"
              name="search"
              solo
              rounded
              dense
              hide-details
              prepend-icon="mdi-magnify"
              placeholder="Искать по id/email"
            />
          </form>
          <v-avatar class="mr-2" color="primary" size="32">
            <!-- <img v-if="user.avatar" alt="Avatar" :src="user.avatar" /> -->
            <strong class="white--text"
              >{{ user.first_name[0] || "?"
              }}{{ user.last_name[0] || "?" }}</strong
            >
          </v-avatar>
          <a href="#" @click.prevent="$store.dispatch('user/logOut')">Выйти</a>
        </template>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="navOpen"
      absolute
      temporary
      color="grey darken-4"
      dark
    >
      <v-list>
        <v-list-item :to="{ name: 'list' }" exact-path>
          <v-list-item-icon>
            <v-icon>mdi-playlist-star</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Компетенции</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isSuperUser" :to="{ name: 'scores' }">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Баллы участников</v-list-item-title>
        </v-list-item>
        <v-list-item
          href="https://field-lungfish-d85.notion.site/4cd3309292e246d49b6ebbc5586c9a45"
          target="_blank"
          rel="noopener noreferrer"
          tag="a"
        >
          <v-list-item-icon>
            <v-icon>mdi-book-open-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Инструкция</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="grey lighten-3">
      <router-view />
    </v-main>

    <v-footer color="grey darken-4" dark>
      <v-container>
        <v-row>
          <v-col md="4" xs="12">
            <v-img
              class="mb-2"
              width="100px"
              src="@/assets/kd_logo_full.svg"
            ></v-img>
            &copy;{{ new Date().getFullYear() }} —
            <strong>Кружковое движение</strong>
          </v-col>
          <v-col md="8">
            <v-btn
              v-for="link in links"
              :key="link"
              color="white"
              text
              rounded
              class="my-2"
            >
              {{ link }}
            </v-btn>
            <div v-if="messages.length" class="text-right">
              <div>Состояние очередей:</div>
              <v-tooltip
                v-for="(item, idx) in messages"
                :key="idx"
                max-width="280px"
                top
              >
                <template #activator="{ on, attrs }">
                  <span class="mr-1" :title="item" v-bind="attrs" v-on="on">{{
                    item.value
                  }}</span>
                </template>
                <span>{{ item.info }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>
<script>
import { apiClient } from "@/api";
const statsInfo = {
  approximate_number_of_messages_not_visible:
    "Ориентировочное количество сообщений, находящихся в процессе передачи — они уже отправлены получателю, но еще не удалены из очереди или у них не закончился таймаут видимости.",
  approximate_number_of_messages_delayed:
    "Ориентировочное количество отложенных сообщений в очереди, которые еще не готовы к приему",
  approximate_number_of_messages:
    "Ориентировочное количество сообщений в очереди,которые готовы к получению",
};
export default {
  name: "AppLayout",
  data() {
    return {
      links: [],
      navOpen: false,
      queueStats: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isSuperUser() {
      const { user } = this;
      return user?.is_superuser;
    },
    messages() {
      const { queueStats } = this;
      return Object.keys(queueStats)
        .map((key) => {
          if (statsInfo[key]) {
            return {
              value: parseInt(queueStats[key], 10),
              info: statsInfo[key],
            };
          }
          return undefined;
        })
        .filter(Boolean);
    },
  },
  watch: {
    user(val) {
      if (val) {
        this.startPoll();
      }
    },
  },
  created() {
    this._interval = null;
    if (this.user) {
      this.startPoll();
    }
  },
  beforeDestroy() {
    clearTimeout(this._interval);
  },
  methods: {
    async getMessages() {
      const { data } = await apiClient({
        url: "/queue_attributes",
      });

      this.queueStats = data;
    },
    startPoll() {
      clearTimeout(this._interval);
      this._interval = setTimeout(async () => {
        try {
          await this.getMessages();
          this.startPoll();
        } catch (error) {
          clearTimeout(this._interval);
        }
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-link {
  text-decoration: none;
  color: inherit;
}
.app-name {
  max-width: 180px;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  line-height: 1.2;
}
.root-app {
  background-color: #f1f4f5;

  &__header {
    box-shadow: 0 2px 6px rgb(15 82 97 / 20%) !important;
  }
}
.user-block {
  min-height: 81px !important;
  align-items: flex-start !important;
}
.user-info {
  font-size: 12px;
  &__name {
    font-weight: bold;
  }

  &__email {
    opacity: 0.75;
  }
}
</style>
