<template>
  <v-pagination
    v-if="pagesCount > 1"
    v-model="page"
    circle
    :length="pagesCount"
    :total-visible="7"
    :disabled="pending"
  ></v-pagination>
</template>

<script>
export default {
  name: "AppPager",
  props: {
    value: {
      type: Number,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      required: true,
      validation(val) {
        return val > 0;
      },
    },
  },
  computed: {
    page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    pagesCount() {
      return Math.ceil(this.total / this.limit);
    },
  },
};
</script>

<style></style>
