import Vue from "vue";
import VueRouter from "vue-router";
import { authMiddleware, superuserMiddleware } from "./middlewares";
Vue.use(VueRouter);

const routes = [
  {
    name: "home",
    path: "/",
    component: () => import("../views/competence/Index.vue"),
  },
  {
    path: "/competence",
    name: "list",
    redirect: "/",
  },
  {
    path: "/competence/:id",
    component: () => import("../views/competence/_id.vue"),
    children: [
      {
        path: "",
        name: "competence_tracks",
        component: () => import("../views/competence/tracks/List.vue"),
      },
      {
        path: "tracks/:track_id",
        name: "competence_track",
        component: () => import("../views/competence/tracks/_track_id.vue"),
      },
      {
        path: "sync",
        name: "competence_sync",
        component: () => import("../views/competence/Sync.vue"),
      },
      {
        path: "achievements_sync",
        name: "competence_achievements_sync",
        component: () => import("../views/competence/AchievementSync.vue"),
      },
      {
        path: "scores",
        component: () => import("../views/competence/score/Index.vue"),
        children: [
          {
            path: "",
            name: "competence_scores",
            component: () => import("../views/competence/score/Sheet.vue"),
          },
          {
            path: "chart",
            name: "competence_scores_chart",
            component: () => import("../views/competence/score/Chart.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/Users.vue"),
    meta: {
      superuser: true,
    },
  },
  {
    path: "/scores",
    name: "scores",
    component: () => import("../views/Scores.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      authless: true,
    },
  },
  {
    path: "/auth/complete",
    name: "auth",
    component: () => import("../views/Auth.vue"),
    meta: {
      authless: true,
    },
  },
  {
    path: "/access_denied",
    name: "403",
    component: () => import("../views/403.vue"),
    meta: {
      authless: true,
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      authless: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(authMiddleware);
router.beforeEach(superuserMiddleware);

export default router;
