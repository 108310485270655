import Cookies from "js-cookie";
import { ACCESS_TOKEN } from "@/constants";
import { apiClient } from "@/api/client";
// import * as Sentry from "@sentry/vue";

export default {
  namespaced: true,
  state: {
    user: null,
    role: "guest",
    token: Cookies.get(ACCESS_TOKEN),
  },
  actions: {
    async getMe({ commit }) {
      const { data } = await apiClient({
        method: "GET",
        url: "/users/me",
      });
      const user = {
        id: data.talent_id,
        is_superuser: data.is_superuser,
        ...(data.talent_user && data.talent_user),
      };
      commit("SET_STATE", {
        key: "user",
        value: user,
      });
      try {
        // Sentry.configureScope((scope) =>
        //   scope.setUser({
        //     id: user.id,
        //   })
        // );
      } catch (error) {
        // silents
      }
    },
    setAuth({ commit }, payload) {
      Cookies.set(ACCESS_TOKEN, payload.access_token, {
        expires: Math.ceil(payload.expires_in / (60 * 60 * 24)),
        path: "/",
        sameSite: "strict",
      });
      commit("SET_STATE", {
        key: "token",
        value: payload.access_token,
      });
    },
    logOut({ commit }) {
      Cookies.remove(ACCESS_TOKEN, {
        path: "/",
      });
      commit("SET_STATE", {
        key: "token",
        value: null,
      });

      window.location.reload();
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
  },
  getters: {
    isAuthorized(state) {
      return Boolean(state.token && state.user?.id);
    },
    user(state) {
      return state.user;
    },
  },
};
