<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar :color="options.confirmColor" dark dense flat>
        <v-toolbar-title class="white--text">{{
          title || "Подтвердите действие"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="px-4 py-6 black--text">
        <div class="text-body-1" v-html="message"></div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn :color="options.confirmColor" @click.native="agree">{{
          options.confirmText
        }}</v-btn>
        <v-btn :color="options.rejectColor" outlined @click.native="cancel">{{
          options.rejectText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const initialOptions = () => {
  return {
    width: 600,
    zIndex: 200,
    confirmText: "Да, подтверждаю",
    rejectText: "Нет, отмена",
    confirmColor: "primary",
    rejectColor: "grey darken-3",
  };
};
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: initialOptions(),
  }),
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
        if (value === false) {
          this.cancel();
        }
      },
    },
  },
  methods: {
    /**
     * @param {string} title
     * @param {string} message
     * @param {object} options
     */
    // eslint-disable-next-line vue/no-unused-properties
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = {
        ...initialOptions(),
        ...options,
      };
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
